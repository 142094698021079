@import url('https://fonts.googleapis.com/css2?family=Saira:wght@400;600;800&display=swap');

* {
	font-family: 'Saira', sans-serif;
	font-size: 12px;
}

body {
	background: linear-gradient(90deg, #050505 15px, transparent 1%) 50%,
		linear-gradient(#050505 15px, transparent 1%) 50%, hsla(0, 0%, 100%, 0.16);
	background-size: 16px 16px;
	box-shadow: inset 0 0 10000px 10000px rgba(0, 0, 0, 0.75);
	height: 100vh;
	width: 100vw;
	padding: 0;
	margin: 0;
}

.logo {
	height: 30px;
	vertical-align: baseline;
}

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;
}

header {
	font-size: 40px;
	font-weight: 800;
	text-align: center;
	color: #ffffff;
	margin-top: 5%;
}

.sub-title {
	font-size: 30px;
}

button {
	cursor: pointer;
}

.result {
	display: flex;
	margin: 1rem;
}

.input-container {
	color: #ffffff;
	padding: 2rem;
	border-radius: 15px;
	margin: 2rem;
	text-align: center;
}

.input-form {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.url-input {
	display: flex;
	position: relative;
}

.input-form input {
	height: 60px;
	margin: 1rem;
	background-color: #333237;
	border-radius: 4px;
	border: 0;
	padding: 1rem 1rem 0 1rem;
	color: #fff;
	box-sizing: border-box;
	width: 100%;
}

.input-form select {
	height: 60px;
	margin: 1rem;
	background-color: #333237;
	border-radius: 4px;
	border: 0;
	padding: 1rem 2.5rem 0 1rem;
	color: #fff;
	position: relative;
	width: 100%;
	cursor: pointer;
	-moz-appearance: none;
	-webkit-appearance: none;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat, repeat;
	background-position: right 1em top 50%, 0 0;
	background-size: 0.65em auto, 100%;
}

.select-domain {
	position: relative;
	display: flex;
	width: 250px;
}

.input-form button {
	padding: 0 2rem;
	background-color: #ffffff;
	border: 0;
	border-radius: 4px;
	text-transform: uppercase;
	font-weight: 600;
	color: #000000;
	margin: 1rem;
	height: 60px;
}

.input-form label {
	font-size: 9px;
	position: absolute;
	left: 2rem;
	top: 23%;
	z-index: 100;
	color: #00afda;
}

@media (min-width: 750px) {
	.url-input {
		width: 60%;
	}
}

@media (max-width: 749px) {
	.input-form > * {
		width: 100%;
	}
}

.input-form img {
	height: 11px;
	vertical-align: middle;
	padding-right: 0.5rem;
}

.result-container {
	background-color: #171b21;
	color: #ffffff;
	padding: 2rem 0 4rem 0;
	text-align: center;
	width: 100%;
	align-self: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	box-sizing: border-box;
}

.result-container button {
	font-size: 2.5rem;
	padding: 1.2rem 3rem;
	margin: 2rem;
	background-color: #fff;
	border: 0;
	border-radius: 4px;
	font-weight: 600;
	color: #00afda;
	word-break: break-all;
}

[tooltip] {
	position: relative;
}

[tooltip]::before,
[tooltip]::after {
	left: 50%;
	position: absolute;
	opacity: 0;
	transition: all 250ms ease;
	top: 100%;
	margin-top: 15px;
}

[tooltip]::before {
	content: '';
	border-width: 4px 6px;
	border-style: solid;
	border-color: transparent transparent #ffffff transparent;
	transform: translateX(-50%) translatey(-100%);
}

[tooltip]::after {
	content: attr(tooltip);
	background: #ffffff;
	text-align: center;
	color: #00afda;
	padding: 4px 8px;
	font-size: 14px;
	min-width: 80px;
	border-radius: 4px;
	pointer-events: none;
	transform: translateX(-50%) translateY(0);
}

[tooltip]:hover::after,
[tooltip]:hover::before {
	opacity: 1;
}
